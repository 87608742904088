(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-horizontal.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-horizontal.js');

'use strict';

const {
  DrawCardContainer
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const {
  DrawSelectorHeader
} = svs.components.tipsen.drawSelector;
const {
  DrawSelectorLinkItem
} = svs.components.tipsen.drawSelector;
const {
  composeDrawTitleForAnalytics
} = svs.components.tipsen.drawSelector;
const DrawSelectorHorizontal = _ref => {
  var _draws$;
  let {
    draws = [],
    selectedDrawNumber,
    onToggleDrawSelector,
    hasExtraMoneyValue,
    branding,
    sportTypes
  } = _ref;
  const drawSelectorClassNames = ['pg_draw_selector', 'pg_draw_selector_horizontal'];
  branding && drawSelectorClassNames.push("pg_draw_selector--".concat(branding));
  return React.createElement("div", {
    className: drawSelectorClassNames.join(' ')
  }, React.createElement(DrawSelectorHeader, {
    blockClassName: "pg_draw_selector_horizontal",
    hasExtraMoneyValue: hasExtraMoneyValue,
    onToggleFunc: onToggleDrawSelector,
    productId: draws === null || draws === void 0 || (_draws$ = draws[0]) === null || _draws$ === void 0 ? void 0 : _draws$.productId,
    sportTypes: sportTypes
  }), React.createElement("ol", null, draws.map(draw => {
    const isSelected = draw.drawNumber === selectedDrawNumber;
    return React.createElement("li", {
      key: draw.drawNumber
    }, React.createElement(DrawSelectorLinkItem, {
      drawNumber: draw.drawNumber,
      drawTitleForAnalytics: composeDrawTitleForAnalytics(draw),
      isLinkItem: !isSelected,
      onToggleDrawSelector: onToggleDrawSelector,
      productId: draw.productId
    }, React.createElement(DrawCardContainer, {
      branding: branding,
      currentDraw: draw,
      drawCardVariant: DRAW_CARD_VARIANTS.HORIZONTAL_LIST_ITEM,
      isSelected: isSelected
    })));
  })));
};
setGlobal('svs.components.tipsen.drawSelector.DrawSelectorHorizontal', DrawSelectorHorizontal);

 })(window);